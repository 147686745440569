import React from "react";
import Nav from "../components/Nav";
import LoggedNav from "../components/LoggedNav";
import SubscriptionSummary from "../components/SubscriptionSummary"; // Assuming this is the correct import for the SubscriptionPlans component

function SubscriptionSummaryComponent(props) {
  return (
    <>
      {localStorage.getItem("token") ? <LoggedNav customer={props.customer} /> : <Nav customer={props.customer} />}
 
      <SubscriptionSummary /> 
    </>
  );
}

export default SubscriptionSummaryComponent;