import React from "react";
import Nav from "../components/Nav";
import LoggedNav from "../components/LoggedNav";
import SubscriptionPlans from "../components/SubscriptionPlans"; // Assuming this is the correct import for the SubscriptionPlans component

function SubscriptionPage(props) {
  return (
    <>
      {localStorage.getItem("token") ? <LoggedNav customer={props.customer} /> : <Nav customer={props.customer} />}
 
      <SubscriptionPlans /> {/* Render the SubscriptionPlans component */}
    </>
  );
}

export default SubscriptionPage;