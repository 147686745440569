import React from "react";
import { SlLocationPin } from "react-icons/sl";
import { IoMdStopwatch } from "react-icons/io";
import chatIcon from "../images/icons/chat.png";
import { useNavigate } from "react-router-dom";
import profile from '../images/Vector.jpg';
import { isMobile } from "react-device-detect";


function SubcriptionList(props) {
  function titleCase(str) {
    return str.toLowerCase().split(' ').map(function (word) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
  }
  const navigate = useNavigate();

  const navigateToChat = () => {
      navigate("/chef/subscription-message", {
        state: {
          data: {
            
            image: props.content.booking_by_user_profile_image,
            name: props.content.customer_name,
            subscription_status: props.content.subscription_status,
            customer_id: props.content.user_id,
            chef_id: props.content.subscribe_chef_id,
            booking_order_id: props.content.booking_order_id,
          },
        },
      });
  };
  return (
    <div className="col-md-4 col-sm-12">
      <div
        className="border row border-1 m-1 py-4"
        style={{
          borderRadius: "20px",
          boxShadow: "0,0,0,grba(0,0,0,0.2)",
        }}
      >
        <div className="col-2 d-flex flex-column">
          <div
            className="d-flex justify-content-center align-items-center my-2"
            style={{ width: "60px", height: "60px", borderRadius: "50%" }}
          >
            <img
              src={
                props.content.booking_by_user_profile_image
                  ? props.content.booking_by_user_profile_image
                  : profile
              }
              style={{ width: "60px", height: "60px", borderRadius: "50%" }}
              alt=""
            />
          </div>
          <div
            className="d-flex justify-content-center align-items-center my-2"
            style={{
              width: "60px",
              height: "60px",
              borderRadius: "50%",
              border: "1px solid #FF5E41",
              cursor: "pointer",
            }}
            onClick={() => {
              console.log(props.content);

              navigate("/chef/subscription-message", {
                state: {
                  data: {
                    plan_id:props.content.plan_id,
                    plan_name:props.content.plan_name,
                     color_code:props.content.color_code,
                    image: props.content.profile_image,
                    name: props.content.customer_name,
                    subscription_status: props.content.subscription_status,
                    customer_id: props.content.user_id,
                    chef_id: props.content.subscribe_chef_id,
                  },
                },
              });
              console.log(props.content.user_id);
            }}
          >
            <img src={chatIcon} alt="" />
          </div>
        </div>
        <div className="col-10 ">
          <div className="card-body">
            <div className="d-flex justify-content-between m-2">
              <div className="col-10" style={{ padding: "5px" }}>
                <h6 className="fw-bold">
                  {titleCase(props.content.customer_name)}
                </h6>
              </div>
            </div>
            {/* <div className="row m-2">
          
            </div> */}
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <span
                className="mx-1 mb-1 px-2 py-1"
                style={{
                  fontSize: "11px",
                  fontWeight: 700,
                  color: "white",
                  minHeight: "15px",
                  borderRadius: "25px",
                  backgroundColor:
                    props.content.subscription_status === "Active"
                      ? "#00a900"
                      : "#ff0000",
                }}
              >
                {props.content.subscription_status}
              </span>
              <span
                className="mx-1 mb-1 px-2 py-1"
                style={{
                  fontSize: "11px",
                  fontWeight: 700,
                  color: "white",
                  minHeight: "15px",
                  borderRadius: "25px",
                  backgroundColor: props.content.color_code,
                }}
              >
                {props.content.plan_name}
              </span>
            </div>
            <div
              className="m-2"
              style={{
                color: "#000",
              }}
            >
              <div className="row my-2 ">
                <p
                  className="px-3 m-0"
                  style={{ fontSize: isMobile ? "0.9rem" : "1rem" }}
                >
                  SUBCRIPTION ID: {props.content.subscription_no}
                </p>
              </div>
              <div className="row" style={{ fontWeight: "400px" }}>
                <div className="col-1">
                  <p className="p-0 m-1">
                    <SlLocationPin />
                  </p>
                </div>
                <div className="col-10 text-break">
                  <p className="p-0 m-0 pt-1">
                    {props.content.address.replaceAll(",", ", ")}
                  </p>
                </div>
              </div>
              {props.content?.postal_code && (
                <div className="row">
                  <div className="col-1">
                    <p className="p-0 m-1">
                      <SlLocationPin />
                    </p>
                  </div>
                  <div className="col-10 text-break ">
                    <p className="p-0 m-0 pt-1">
                      {props.content?.postal_code ?? ""}
                    </p>
                  </div>
                </div>
              )}
              <div className="row ">
                <div className="col-1">
                  <p className="p-0 m-1">
                    <IoMdStopwatch />
                  </p>
                </div>
                <div className="col-10">
                  <p className="p-0 m-0 pt-1">
                    {props.content.timeslot.map((e, index) => (
                      <span key={index}>
                        {e}
                        {index < props.content.timeslot.length - 1 ? ", " : ""}
                      </span>
                    ))}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubcriptionList;