import React ,{ useEffect , useState}  from "react";
import Nav from "../components/Nav";
import {  useLocation } from "react-router-dom";

import LoggedNav from "../components/LoggedNav";
import SubscriptionMessageScreen from "../components/SubscriptionMessageScreen";

function SubscriptionMessage(props) {

  const { state } = useLocation();

  useEffect(() => {

    console.log(state);
    
 

    // eslint-disable-next-line
  }, []);

  return (
    <>
      {localStorage.getItem("token") ? <LoggedNav customer={props.customer} /> : <Nav customer={props.customer} />}
 
      <SubscriptionMessageScreen customer={props.customer} data={state} /> 
    </>
  );
}

export default SubscriptionMessage;