import React from 'react';
import { createRoot } from "react-dom/client";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import ScrollToTop from './helpers/ScrollToTop';

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Router basename='/'>
    <ScrollToTop />
    <Toaster />
    <App />
  </Router>
);
reportWebVitals();



// sudo scp -r ./build/* /var/www/build/
