


import React, { useEffect, useState } from 'react';
import '../CSS File/SubscriptionPlanCard.css';

const SubscriptionCard = ({ index, type, price, features, buttonText, popular, onSubscribe, changeCard, activeIndex,option }) => {

  let [featureList,setfeature]=useState([])
  featureList = Array.isArray(features) ? features : [];
 useEffect(()=>{
  setfeature([...featureList,features]);
 },[])
  return (
    <div className={`pricing-card ${activeIndex === index ? 'active' : ''}`} onClick={() => changeCard(index)}>
      {/* {popular && <div className="popular-badge">Popular</div>} */}
      <div className="card-header">
        <h3>{type}</h3>
        <h2><span className="price">${price}</span>/ Booking</h2>
      </div>
      <ul className="card-features" style={{ listStyleType: 'disc' ,
        paddingLeft:"10px"
      }} >
        {featureList.map((feature, idx) => (
          <li  key={idx}>{feature}</li>
        ))}
      </ul>
      <button className="card-button" onClick={() => onSubscribe(option)}>
        {buttonText}
      </button>
    </div>
  );
};

export default SubscriptionCard;