import React from "react";

function Websitecards() {
  return (
    <div id="websiteCards">
<div className="row justify-content-center "  >
<div className="col-md-2 m-3  text-center">
  <div className="p-4 rounded" style={{ minHeight : "160px", backgroundColor: '#D45533', color: '#fff' }}>
    <h2>1.5 +</h2>
    <p>Year since our launch</p>
  </div>
</div>
<div className="col-md-2 m-3  text-center">
  <div className="p-4 rounded" style={{ minHeight : "160px", backgroundColor: '#D45533', color: '#fff' }}>
    <h2>25 +</h2>
    <p>Chefs have joined us</p>
  </div>
</div>
<div className="col-md-2 m-3  text-center">
  <div className="p-4 rounded" style={{ minHeight : "160px", backgroundColor: '#FFFFFF', color: '#D45533' }}>
    <h2>500 +</h2>
    <p>Guests enjoyed our experience</p>
  </div>
</div>
<div className="col-md-2 m-3  text-center">
  <div className="p-4 rounded" style={{ minHeight : "160px", backgroundColor: 'rgba(212, 85, 51, 1)', color: '#fff' }}>
    <h2>13 +</h2>
    <p>Cities Available</p>
  </div>
</div>
</div>
    </div>

  );
}

export default Websitecards;











