import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import BrowseChefGuest from "../components/BrowseChefGuest";
import BrowseChefMobileGuest from "../components/BrowseChefMobileGuest";
import { useLocation } from 'react-router-dom';

export default function ChefDetail() {
    const [loggedIn, setLoggedIn] = useState();
    let navigate = useNavigate();

    let location = useLocation();
const subscriptionAddressLocationData = location.state?.option;


    const checkLoggedInUser = () => {
        let token = localStorage.getItem("token");
        if (token) {
            setLoggedIn(true);
        } else {
            setLoggedIn(false);
        }
    };

    useEffect(() => {
        // let role = localStorage.getItem("role");
        // if (localStorage.getItem("token")) {
        //     if (role === null) {
        //         navigate("/");
        //     }
        //     if (role === "2") {
        //         navigate("/chef");
        //     }
        // }
        // checkLoggedInUser();
    }, []);

    return (
        // loggedIn ? (isMobile ? <BrowseChefMobile customer={true} /> : <BrowseChef customer={true} />) : (isMobile ? <BrowseChefMobileGuest customer={true} /> : <BrowseChefGuest customer={true} />)
        isMobile ? <BrowseChefMobileGuest customer={true}  option={subscriptionAddressLocationData}/> : <BrowseChefGuest customer={true}  option={subscriptionAddressLocationData}/>
    );
}
