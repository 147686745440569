import React from "react";
import image from "../images/logo.png";
import fb from "../images/icons/fb.png";
import insta from "../images/icons/in.png";
import twitter from "../images/icons/tw.png";
import { isMobile } from "react-device-detect";

function Footer(props) {
  return (
    <>
      <footer className="py-4" style={props.top ? { top: 0 } : {}}>
        {/* <div className="d-flex justify-content-between flex-row">
          <div className={isMobile ? "container d-flex justify-content-start align-items-center" : "container d-flex justify-content-center align-items-center"}>
            <a className="navbar-brand" href="/">
              <img src={image} alt="chef-republic-logo" />
            </a>
          </div>
          <div className="container d-flex justify-content-center" id="footerAltMarkup">
            <div className="navbar-nav d-flex flex-row justify-content-center align-items-center">
              <a className="nav-link mx-1" aria-current="page" href="https://www.facebook.com/profile.php?id=100088092789495">
                <img src={fb} alt="brand-logo" />
              </a>
              <a className="nav-link mx-1" href="https://twitter.com/chefrepublic_us">
                <img src={twitter} alt="brand-logo" />
              </a>
              <a className="nav-link mx-1" href="https://www.instagram.com/chef.republic.us/">
                <img src={insta} alt="brand-logo" />
              </a>
            </div>
          </div>
          <div className={isMobile ? "container d-flex justify-content-center align-items-center text-end" : "container d-flex justify-content-center align-items-center"}>
            Copyrights reserved ©
          </div>
        </div>
        <div className="container d-flex justify-content-center my-3 mobile-footer">
          <div className="navbar-nav d-flex flex-row justify-content-center align-items-center">
            <a className="nav-link mx-1" aria-current="page" href="https://www.facebook.com/profile.php?id=100088092789495">
              <img src={fb} alt="brand-logo" />
            </a>
            <a className="nav-link mx-1" href="https://twitter.com/chefrepublic_us">
              <img src={twitter} alt="brand-logo" />
            </a>
            <a className="nav-link mx-1" target="_blank" href="https://www.instagram.com/chef.republic.us/">
              <img src={insta} alt="brand-logo" />
            </a>
          </div>
        </div>
        <div className="container d-flex justify-content-center my-3">
          <div className="navbar-nav d-flex flex-row justify-content-center align-items-center" style={{
            color: "#8E8E8E"
          }}>
            <a className="nav-link mx-1 text-center" href={props.customer ? "/terms-and-conditions" : "/chef/terms-and-conditions"}>
              Term & Conditions
            </a>
            <a className="nav-link mx-1 text-center" href="mailto:support@chefrepublic.us">
              Support
            </a>
            <a className="nav-link mx-1 text-center" href={props.customer ? "/privacy-policy" : "/chef/privacy-policy"}>
              Privacy Policy
            </a>
          </div>
        </div> */}

        <div className="footer" style={{backgroundColor: "#FFEEEB", padding:"5vh"}} >
         <div className="row" >
          <div className="col-md-7" style={{ borderRight: isMobile ? "none" : "1px solid orange" }}>
              <div className="row" style={{}}>
                <div className="col-md-9" >
                  <div className="text-center" >
                        <a  href="/">
                          <img 
                            src={image} 
                            alt="chef-republic-logo" 
                           
                          />
                        </a>
                  </div>
                  <div className="navbar-nav d-flex flex-row justify-content-center align-items-center">
            <a className="nav-link mx-1" aria-current="page" href="https://www.facebook.com/profile.php?id=100088092789495">
              <img src={fb} alt="brand-logo" />
            </a>
            <a className="nav-link mx-1" href="https://twitter.com/chefrepublic_us">
              <img src={twitter} alt="brand-logo" />
            </a>
            <a className="nav-link mx-1" target="_blank" href="https://www.instagram.com/chef.republic.us/">
              <img src={insta} alt="brand-logo" />
            </a>
                </div>

                <div className="row" style={{ color: "#8E8E8E"}}>
          <div className="col-6" style={{   borderRight:"1px solid orange"}}>
   <a className="nav-link mx-1 text-end " href={props.customer ? "/privacy-policy" : "/chef/privacy-policy"}>
              Privacy Policy
            </a>
          </div>
          <div className="col-6">
          <a className="nav-link mx-1 text-start" href={props.customer ? "/terms-and-conditions" : "/chef/terms-and-conditions"}>
              Term & Conditions
            </a>
          </div>
        </div>
              </div>

              <div  className={isMobile ? "mt-4 col-md-3" : "col-md-3"}>
              <h4 ><b>Quick Links:  </b></h4>   
              <ul style={{listStyle:"none"}} className="mt-3"  >
                <li className="pb-1" >Home</li>
                <li className="pb-1" >What we offer</li>
                <li className="pb-1" >How it works</li>
                <li className="pb-1" >Benefits</li>
                <li className="pb-1" >Review</li>
              </ul>
              </div>
              </div>
          </div>
            <div className="col-md-5">
            <h4><b> Cities We Serve Across: </b></h4>
<div className="row mt-3">
  <div className="col-md-6">
   
<ul>
  <li>Chef's in Aston</li>
  <li>Chef's in Bayonne</li>
  <li>Chef's in Bensalem</li>
  <li>Chef's in Bridgewater</li>
  <li>Chef's in Drexel Hill</li>
  <li> Chef's in Edison</li>

</ul>
  </div>
  <div className="col-md-6">
  <ul>

<li>Chef's in Glen mills</li>
<li>Chef's in Lansdale</li>
<li>Chef's in Levittown</li>
<li>Chef's in Montville</li>
<li>Chef's in New Castle</li>
<li>Chef's in Philadelphia</li>
<li>Chef's in Plainsboro</li>

</ul>
  </div>
</div>
            </div>
         </div>
         <div>
         <div className={isMobile ? "container d-flex justify-content-center align-items-center text-end" : "container d-flex justify-content-center align-items-center"}>
            Copyrights reserved ©
          </div>
         </div>
        </div>
      </footer >
    </>
  );
}

export default Footer;
