import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import { Formik, ErrorMessage } from "formik";


function PaymentDetail(props) {

  const location  = useLocation();
  const  option  = location.state || {};
  const role  = localStorage.getItem("role");
  const planID =localStorage.getItem('plan_id');
  let [address,setAddress]=useState("")
  let [planname,setPlanName]=useState("")
  let [zipCode,setZip]=useState("")
  
  
 
  
  console.log(option);

   
  let navigate = useNavigate();
  useEffect(() => {
    var title = `Add Card Details | CHEF REPUBLIC`;
    var desc = ""
    const titleTag = document.querySelector('title');
    titleTag.innerText = title;
    const metaTitle = document.querySelector("meta[name='title']");
    metaTitle.setAttribute('content', title)
    const metaDescription = document.querySelector("meta[name='description']");
    metaDescription.setAttribute('content', desc)
    const metaTitleOG = document.querySelector("meta[property='og:title']");
    metaTitleOG.setAttribute('content', title)
    const metaDescriptionOG = document.querySelector("meta[property='og:description']");
    metaDescriptionOG.setAttribute('content', desc)
    const metaTitleTwitter = document.querySelector("meta[property='twitter:title']");
    metaTitleTwitter.setAttribute('content', title)
    const metaDescriptionTwitter = document.querySelector("meta[property='twitter:description']");
    metaDescriptionTwitter.setAttribute('content', desc)

  
    getUserAddress();
    // if (role === null) {
    //   navigate("/sign-in")
    //   window.location.reload()
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleSubscribe = (options) => {

    console.log(options);
  
if(!(localStorage.getItem("logged_user_id"))){
  localStorage.setItem('address', address);
  localStorage.setItem('zipcode', zipCode)
  navigate("/chef-list", {
    state: {
      option: options,
    },
  });
}

   

    navigate("/chef-list", {
      state: {
        option: options,
      },
    });
  };



  let getUserAddress=async(id)=>{
    try {
      let response = await fetch(
        `${process.env.REACT_APP_BASE_URL}subscription/fetch_address`,
        {
          method: "POST",
     headers: {
            "Authorization": "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
           body: JSON.stringify({
            "user_id":`${localStorage.getItem("logged_user_id")}`,                   
           })
                                        
        }
      );
      let responseData = await response.json();

      if (responseData.status){
        console.log(responseData.address);
        setAddress(responseData.address)
        setZip(responseData.zip_code)
        setPlanName(responseData.plan_name)

      }
      else{
        debugger
        FetchPlanDetails(planID);
      }
      

    } catch (error) {
      console.error("Error fetching subscription data:", error);
}
}

const FetchPlanDetails = async (planID) => {
  try {
    let response = await fetch(
      `${process.env.REACT_APP_BASE_URL}subscription/fetch_plan_details`,
      {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          plan_id: planID,
        }),
      }
    );
    let responseData = await response.json();

    if (responseData.status) {
      localStorage.setItem("chef_status", responseData.chef_status);
      localStorage.setItem("status", responseData.status);
      localStorage.setItem("plan_status", responseData.plan_status);
      localStorage.setItem("plan_id", responseData.plan_id);
      localStorage.setItem("plan_name", responseData.plan_name);
      localStorage.setItem("price", responseData.price);
      setPlanName(responseData.plan_name)
      navigate('/add-Address-details');
    } else {
      // toast.error('Please login before subscribing, then apply Subscription Plan');
    }
  } catch (error) {
    console.error('Error fetching subscription data:', error);
  }
}
 

  return (
    <>
      <div className="row m-0 p-0">
        <div
          className="col-md-7"
          style={{
            background: `linear-gradient(to left, rgba(255, 255, 255, 1) 0%,  rgba(255, 255, 255, 0.8) 10%, rgba(255, 255, 255, 0.2) 18%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 100%), url(${props.image}) no-repeat`,
            height: "100vh",
            display: isMobile ? "none" : "block",
          }}
        ></div>
        <div className="col-md-5 d-flex align-items-center pt-5 justify-content-center flex-column">
          <div
            className="d-flex justify-content-center flex-column"
            style={{ width: "60%" }}
          >
            <h3
              className="mb-3 text-center"
              style={{
                color: "rgba(30, 30, 30, 1)",
                fontSize: "1.25rem",
                fontWeight: 700,
              }}
            >
              <p>You Selected </p>
              <span style={{ color: "red" }}> "{planname}"</span>
            </h3>

            <h5
              className="mb-3 text-center"
              style={{
                color: "rgba(30, 30, 30, 1)",
                fontSize: "1rem",
                fontWeight: 700,
              }}
            >
              Enter Your Full Address
            </h5>
          </div>

          <Formik
            initialValues={{ name_on_card: "" }}
            validate={(values) => {
              const errors = {};
              const d = new Date();
              if (!values.name_on_card) {
                errors.name_on_card = "Card Holder Name is Required";
              }

              return errors;
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form
                style={{ width: "80%" }}
                id="addCard"
                onSubmit={handleSubmit}
              >
                <div className="mb-3">
                  <textarea
                    placeholder="Address"
                    type="text"
                    rows={5}
                    name="address"
                    className={
                      errors.address
                        ? "form-control shadow-none invalid"
                        : "form-control shadow-none"
                    }
                    // onChange={handleChange}
                    onBlur={handleBlur}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  >
                  </textarea>
                  
                  <ErrorMessage name="address">
                    {(msg) => <h6 className="error-msg">{msg}</h6>}
                  </ErrorMessage>
                </div>
                <div className="mb-3">
                  <textarea
                    placeholder="Enter ZIP Code"
                    type="text"
                    rows={1}
                    name="zipcode"
                    className={
                      errors.address
                        ? "form-control shadow-none invalid"
                        : "form-control shadow-none"
                    }
                    // onChange={handleChange}
                    onBlur={handleBlur}
                    value={zipCode}
                    onChange={(e) => setZip(e.target.value)}
                  >
                  </textarea>
                  
                  <ErrorMessage name="address">
                    {(msg) => <h6 className="error-msg">{msg}</h6>}
                  </ErrorMessage>
                </div>
                <h6 className="text-center error-msg validation p-0">{""}</h6>

                <div className="mb-3 text-center">
                  <button type="submit" className="mb-4 btn-orange w-100" onClick={()=>{
                    handleSubscribe({
                      "zipCode":zipCode,
                      "address":address
                    })
                  }}>
                    Confirm
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default PaymentDetail;
