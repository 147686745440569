import React from 'react';
import { RxCross1 } from "react-icons/rx";
import { ClipLoader } from 'react-spinners';
import { isMobile } from "react-device-detect";


const DynamicDialog = (props) => {
  console.log(props.title)
  const dialogContainerStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    display: props.openDialog ? 'flex' : 'none',
    alignItems: 'center',
    justifyContent: 'center',
    backdropFilter: 'blur(4px)',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjusted for better background blur visibility
    zIndex: 1000, // Ensure it overlays other elements
  };

  const dialogContentStyles = {
    width:  isMobile?'75%': '33.33%',
    backgroundColor: '#FAFAFA',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
    padding: '20px',
  };

  const dialogHeaderStyles = {
    display: 'flex',
    justifyContent: 'flex-end',
  };

  const closeButtonStyles = {
    width: '24px',
    height: '24px',
    backgroundColor: '#FF5E41',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  };

  const closeIconStyles = {
    color: '#ffffff',
  };

  const dialogBodyStyles = {
    marginTop: '15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: '16px',
    color: 'black',
  };

  const addSubscriptionButtonStyles = {
    border: 'none',
    padding:'5px',
    height: '2.25rem',
    width: '200px',
    backgroundColor: '#FF5E41',
    borderRadius: '1rem',
    color: '#ffffff',
    textAlign: 'center',
    padding: '0.5rem',
    marginTop: '30px',
    cursor: 'pointer',
  };

  const addSubscriptionButton2Styles = {
    border: 'none',
    padding:'5px',
    height: '2.25rem',
    width: '200px',
    backgroundColor: 'transparent',
    borderRadius: '1rem',
    border: '1px solid #D3D3D3',
    color: '#FF5E41',
    textAlign: 'center',
    padding: '0.5rem',
    marginTop: '30px',
    cursor: 'pointer',
  };

  

  return (

  (props.getDialog === 1)?<><div style={dialogContainerStyles}>
     
  <div style={dialogContentStyles}>
    <div style={dialogHeaderStyles}>
      <div  style={closeButtonStyles} onClick={() => props.closeDialog(false)}>
        <span style={closeIconStyles}> <RxCross1 /></span>
      </div>
    </div>
    <div style={dialogBodyStyles}>
    <div ><img src={props.imagesrc} alt='no image'></img> </div>
      <div>{props.title === "" ? <ClipLoader size={35} color="#FF5E41" /> : props.title}</div>
    </div>
  </div>
  </div></> : <>
 
      <div style={dialogContainerStyles}>
        <div style={dialogContentStyles}>
          <div style={dialogHeaderStyles}>
            <div style={closeButtonStyles} onClick={() => props.closeDialog(false)}>
              <span style={closeIconStyles}><RxCross1 /></span>
            </div>
          </div>
          <div style={dialogBodyStyles}>
          <div>{ props.title}</div>
            <button style={addSubscriptionButtonStyles} onClick={props.changeScreen}>
              {props.buttonText}
            </button>
            <button className='mt-2'
              style={{ ...addSubscriptionButton2Styles,  display: props.showSecondButton ? 'block' : 'none' }}
              onClick={props.changeScreenSecondButton}
            >
              {props.secondButtonText}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default DynamicDialog;
